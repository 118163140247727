.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.gulzar-regular {
  font-family: "Gulzar", serif;
  font-weight: 400;
  font-style: normal;
}
.noto-naskh-arabic {
  font-family: "Noto Naskh Arabic", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  background-color: #faf6f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
